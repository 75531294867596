import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { nativeService } from '@bluecodecom/native';

import Button from '@bluecodecom/common/ui/Button';
import Typo from '@bluecodecom/common/ui/Typo';

import { banksHooks } from '@bluecodecom/bank-search-webview/features/banks';

import atSrc from './assets/at.png';
import deSrc from './assets/de.png';

const Partners = () => {
  const { t } = useTranslation();
  const { currentCountry } = banksHooks.useBanksContext();

  const country = currentCountry?.toLowerCase() as 'de' | 'at';

  const handleClick = useCallback(() => {
    switch (country) {
      case 'at':
        return nativeService.openExternal('https://bank99.at/konto');

      case 'de':
        return nativeService.openExternal(
          'https://www.sparkasse-saarbruecken.de/de/home/privatkunden/girokonto/girokonto-online.html',
        );
    }
  }, [country]);

  if (!country) {
    return null;
  }

  return (
    <div className="flex-1 px-4 overflow-auto scrollbar-hide">
      <div className="flex flex-col justify-center space-y-4">
        <Typo weight="700" size="2xl" className="text-center">
          {t('banks.partners.title')}
        </Typo>
        <Typo weight="300" className="text-center">
          {t('banks.partners.description')}
        </Typo>
        <div className="flex flex-col gap-4 p-4 shadow-md dark:shadow-darkMd dark:bg-gray-500 rounded-2xl bg-gray-0">
          <div className="flex items-center gap-3">
            <img
              src={country === 'at' ? atSrc : deSrc}
              alt="at"
              className="h-12"
            />
            <Typo className="flex-1" weight="700" size="xl">
              <Trans i18nKey={`banks.partners.${country}.title`} />
            </Typo>
            {country === 'at' && (
              <div className="self-start px-3 py-1 bg-green-100 rounded-2xl">
                <Typo color="text-green-500">99 € Bonus</Typo>
              </div>
            )}
          </div>
          <Typo weight="300">{t(`banks.partners.${country}.description`)}</Typo>
          <Button size="small" variant="secondary" onClick={handleClick}>
            {t(`banks.partners.button`)}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Partners;
